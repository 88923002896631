
import { Vue, Component } from 'vue-property-decorator'
import { parse } from 'papaparse'

const parseCSV = (file: File) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      error: err => {
        reject(err)
      },
      complete: results => {
        return resolve(results.data)
      },
      header: true,
    })
  })
}
type Data = {
  customer: string
  shopCode: string
  ecUserCode: string
}
@Component
export default class ECUserImportPage extends Vue {
  file: File | null = null
  data: Data[] = []
  uploading = false

  async onFileChanged(file: null | File) {
    console.log(file)
    if (file) {
      const data: any = await parseCSV(file)
      this.data = data
        .map((x: any) => ({
          customer: x.cipID,
          shopCode: x['ShopID'],
          ecUserCode: x['EC ID'],
        }))
        .filter((x: Data) => Boolean(x.customer))
    } else {
      this.data = []
    }
  }

  async upload() {
    this.uploading = true
    try {
      const data: Data[] = await this.$api
        .customers()
        .registerEcSiteUser(this.data)
      this.data = []
      this.file = null
      this.$toast.success(`${data.length}件更新しました。`)
    } catch (err) {
      console.error(err)
      this.$toast.error('アップロードに失敗しました。')
    }
    this.uploading = false
  }
}
